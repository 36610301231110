import React from "react"
import LoadingGif from '../static/img/loading.gif'
import { firebaseAuth } from '../config/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt} from '@fortawesome/free-solid-svg-icons';

class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.signOut = this.signOut.bind(this);
    }

    signOut() {
        localStorage.setItem('authed', false);localStorage.setItem('authorized', false);
        firebaseAuth().signOut();
        
    }

    render() {

        return (
            <section className="hero">
                <div className="hero-body">
                    <div className="container ">
                        <div className="columns is-vcentered">
                            <div className="column has-text-centered">
                                <img src={LoadingGif} alt="En cours de chargement" />
                            </div>
                        </div>
                        <div className="columns is-vcentered">
                            <div className="column has-text-centered">
                            <button className="button custom-font is-primary" onClick={() => { this.signOut() }}><FontAwesomeIcon width="16" icon={faSignOutAlt} />&nbsp;Déconnexion</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Loading