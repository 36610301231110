var config = {
  apiKey: "AIzaSyC86aDl6SxZdRdRFUmhHuBhbMEiIyfWj3A",
  authDomain: "upd-ecommerce-data.firebaseapp.com",
  databaseURL: "https://upd-ecommerce-data-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "upd-ecommerce-data",
  storageBucket: "upd-ecommerce-data.appspot.com",
  messagingSenderId: "399977331495",
  appId: "1:399977331495:web:5a2df7a519c0cdacec8702"
};

export default config;