import React, { Component } from "react";
import Loadable from "react-loadable";
import Home from "./pages/home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { firebaseAuth, firebaseAuth2 /*firestore*/ } from "./config/firebase";
import Login from "./components/login";
import Loading from "./components/loading";
import "moment/locale/fr";

function MyLoadingComponent(props) {
  if (props.pastDelay) {
    return <Loading />;
  } else if (props.error) {
    return (
      <div className="title">
        Une erreur s'est produite lors du chargement de la page
      </div>
    );
  } else {
    return null;
  }
}
//const AsyncMarketing = Loadable({
//loader: () => import("./pages/marketing" /* webpackChunkName: "marketing"),
//loading: MyLoadingComponent,
//delay: 100,
//timeout: 10000
//});

const AsyncCoupon = Loadable({
  loader: () => import("./pages/coupons" /* webpackChunkName: "coupon" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000,
});

//const AsyncCouponPerso = Loadable({
//loader: () => import("./pages/coupons-perso" /* webpackChunkName: "coupon-perso" */),
//loading: MyLoadingComponent,
//delay: 100,
//timeout: 10000
//});

//const AsyncShipUp = Loadable({
//loader: () => import("./pages/shipup" /* webpackChunkName: "shipup" */),
//loading: MyLoadingComponent,
//delay: 100,
//timeout: 10000
//});

const AsyncNotFound = Loadable({
  loader: () => import("./pages/error" /* webpackChunkName: "error" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: localStorage.getItem("authed")
        ? localStorage.getItem("authed")
        : false,
      authed: localStorage.getItem("authorized")
        ? localStorage.getItem("authorized")
        : false,
      token: "nogo",
    };
  }

  componentDidMount() {
    var currentComponent = this;
    firebaseAuth().onAuthStateChanged(function (user) {
      if (user) {
        var string = user.email;
        var substring = "@ultrapremiumdirect.com";
        if (string.indexOf(substring) !== -1) {
          firebaseAuth2.currentUser.getIdToken().then(function (token) {
            currentComponent.setState(
              { token: token, authorized: true, authed: true },
              function (result) {
                localStorage.setItem("authed", true);
                localStorage.setItem("authorized", true);
              }
            );
          });
        } else {
          currentComponent.setState(
            { authorized: false, authed: true },
            function (result) {
              firebaseAuth().signOut();
              localStorage.clear();
            }
          );
        }
      } else {
        currentComponent.setState(
          { authorized: false, authed: false },
          function () {
            firebaseAuth().signOut();
            localStorage.clear();
          }
        );
      }
    });
  }

  render() {
    return (
      <div>
        {!this.state.authed && !this.state.authorized ? <Login /> : null}
        {this.state.authed && !this.state.authorized ? <Loading /> : null}
        {this.state.authorized && this.state.authed ? (
          <section className="body-section">
            <BrowserRouter>
              <Switch>
                {/*<Route path="/" exact component={() =><Home token={this.state.token}/>} />
<Route path="/marketing" component={() =><AsyncMarketing token={this.state.token}/>}/>*/}
                <Route
                  path="/"
                  component={() => <AsyncCoupon token={this.state.token} />}
                />
                {/*<Route path="/coupons-perso" component={() =><AsyncCouponPerso token={this.state.token}/>
              <Route path="/shipup" component={() =><AsyncShipUp token={this.state.token}/>}/>*/}
                <Route component={AsyncNotFound} />
              </Switch>
            </BrowserRouter>
          </section>
        ) : null}
      </div>
    );
  }
}

export default App;
