
import React, { Component } from 'react';
import * as moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import Iframe from "./iframe"

moment.locale('fr')

class Table extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            toggle: 0,
            id: 0,
            active: false,
            loading: false,
            iframeContent: '',
            email: '',
        }
        this.toggler = this.toggler.bind(this);
        this.untoggler = this.untoggler.bind(this);
        this.getContent = this.getContent.bind(this)
        this.toggleClass = this.toggleClass.bind(this)
    }

    toggleClass() {
        const currentState = this.state.active
        this.setState({ active: !currentState, show: false })
    }

    getContent() {
        var currentComponent = this;
        this.setState({
            loading: true
        }, function () {

            var data = "email=" + this.state.email + "&messageId=" + this.state.id
            axios
                .post("https://europe-west1-upd-ecommerce-data.cloudfunctions.net/front/transactional/list", data, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                        'Authorization': 'Bearer ' + this.props.token
                    },
                })
                .then(response => {

                    data = "uuid=" + response.data
                    axios
                        .post("https://europe-west1-upd-ecommerce-data.cloudfunctions.net/front/transactional/content", data, {
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                                'Authorization': 'Bearer ' + this.props.token
                            },
                        }).then(response2 => {
                            currentComponent.setState({
                                loading: false,
                                iframeContent: response2.data
                            })
                        })


                })
                .catch(error => {
                    currentComponent.setState({
                        active: true,
                        loading: false

                    })
                })
        });
    }

    toggler(index, id, email) {
        this.setState({ toggle: index, toggled: true, id: id, email: email })
    }

    untoggler() {
        this.setState({ toggle: 0, toggled: false, iframeContent: '' })
    }

    render() {
        function tradEvent(event) {
            var event_trad = "";

            switch (event) {
                case 'opened':
                    event_trad = "Ouvert";
                    break;
                case 'requests':
                    event_trad = "Envoyé";
                    break;
                case 'delivered':
                    event_trad = "Délivré";
                    break;
                case 'clicks':
                    event_trad = "Cliqué";
                    break;
                case 'blocked':
                    event_trad = "Bloqué";
                    break;
                case 'hardBounces':
                    event_trad = "Erreur";
                    break;
                case 'deferred':
                    event_trad = "Différé";
                    break;
                case 'softBounces':
                    event_trad = "Erreur";
                    break;
                case 'invalid':
                    event_trad = "Invalide";
                    break;
                default:
                    event_trad = "";
                    break;

            }
            return event_trad;

        }



        var outputData = this.props.emails.map((data, index) => {
            console.log(data)
            return (data.event === "requests" && (this.state.toggle === index || !this.state.toggled) ?
                <div className="columns has-text-meft is-vcentered is-mobile is-variable is-1" key={index}>
                    <div className="column is-8">
                        <button className="button is-info is-small" disabled={this.state.toggled} onClick={() => { this.toggler(index, data.messageId, data.email) }}>Voir</button>&nbsp;{data.subject}
                    </div>
                    <div className="column is-2">

                        {tradEvent(data.event)}
                    </div>
                    <div className="column is-2">
                        {moment(data._date).format('LLLL')}
                    </div>
                </div> : null
            )
        })


        var outputDetails = this.props.emails.map((data, index) => {
            return (data.messageId === this.state.id ?
                <div className="columns has-text-centered is-vcentered is-mobile is-variable is-1" key={index}>
                    <div className="column is-8">
                        {tradEvent(data.event)}
                    </div>
                    <div className="column is-4">

                        {moment(data._date).format('LLLL')}

                    </div>
                </div> : null
            )
        })

        return (
            <>
                <div className="columns has-text-centered is-vcentered is-mobile is-variable is-1">
                    <div className="column is-8">
                        <p className="bd-notification is-fullwidth text">Objet</p>
                    </div>
                    <div className="column is-2">
                        <p className="bd-notification is-fullwidth text">
                            Evénement
                    </p>
                    </div>
                    <div className="column is-2">
                        <p className="bd-notification is-fullwidth text">Date</p>
                    </div>
                </div>
                {outputData}
                {this.state.toggled ?
                    <>
                        <div className="columns has-text-centered is-vcentered is-mobile is-variable is-1">
                            <div className="column is-8">
                                <p className="bd-notification2 is-fullwidth text">Etat</p>
                            </div>
                            <div className="column is-4">
                                <p className="bd-notification2 is-fullwidth text">
                                    Date
                                </p>
                            </div>
                        </div>
                        {outputDetails}                        
                        <div className="columns has-text-centered is-vcentered is-mobile is-variable is-1">
                            <div className="column is-6">
                                <button className={this.state.loading ? "button is-info is-loading" : "button is-info"} onClick={this.getContent}><FontAwesomeIcon width="16" icon={faEnvelope} />&nbsp;Voir l'email envoyé</button>
                            </div>
                            <div className="column is-6">
                                <button className="button is-danger" onClick={this.untoggler}>Fermer</button>
                            </div>
                        </div>
                        
                        {this.state.iframeContent.length>0?<Iframe content={this.state.iframeContent} />:null}
                        </> : null}
                    <div className={this.state.active ? "modal is-active" : "modal"}>
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Erreur</p>
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={this.toggleClass}
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            Une erreur s'est produite (si elle persiste, déconnectez-vous pour ensuite vous reconnecter!)
                    </section>
                        <footer className="modal-card-foot">
                            <button className="button is-primary is-uppercase home-button" onClick={this.toggleClass}>
                                Ok
                            </button>
                        </footer>
                    </div>
                </div>
            </>
        )
    }
}

export default Table