import React, { Component } from 'react';
import { firebaseAuth2, googleProvider } from '../config/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authed: false,
            loading: true,
            email: '',
            EmailButtonLoading: false,
            password: ''
        }
        this.login = this.login.bind(this);
        this.toggleEmail = this.toggleEmail.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
    }

    toggleEmail(event) {
        this.setState({ email: event.target.value });
    }

    togglePassword(event) {
        this.setState({ password: event.target.value });
    }


    login() {
        var currentComponent = this;
        currentComponent.setState({ EmailButtonLoading: true })

        firebaseAuth2
            .signInWithPopup(googleProvider)
            .then((result) => {
                currentComponent.setState({ EmailButtonLoading: false })
            }).catch((error) => {
                currentComponent.setState({ EmailButtonLoading: false })
            });
    }

    render() {
        return (
            <section className="hero is-large">
                <div className="hero-body">
                    <div className="container is-widescreen">
                        <div className="columns is-vcentered">
                            <div className="column has-text-centered">
                                <p className="title">Internal tool</p>
                                {/*<div className="field">
                                <label className="label">Email</label>
                                <div className="control super-control has-icons-left has-icons-right">
                                    <input className="input" name="email" value={this.state.email} required type="email"
                                        placeholder="Email" autoComplete="username email" onChange={this.toggleEmail} />
                                    <span className="icon is-left">
                                        <FontAwesomeIcon width="16" icon={faEnvelope} />
                                    </span>
                                    {this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? <span className="icon is-small is-right">
                                        <FontAwesomeIcon width="16" icon={faCheck} />
                                    </span> : null}
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Mot de passe</label>
                                <div className="control super-control has-icons-left">
                                    <input className="input" name="password" id="pwd-login" required type="password"
                                        placeholder="Password" value={this.state.password} onChange={this.togglePassword} />
                                    <span className="icon is-left">
                                        <FontAwesomeIcon width="16" icon={faLock} />
                                    </span>
                                </div>
        </div>*/}
                                <div className="field">
                                    <p className="control">
                                        <button className={this.state.EmailButtonLoading ? "button is-primary is-loading" : "button is-primary"} id="login-btn" onClick={() => { this.login() }}>
                                            <FontAwesomeIcon width="16" icon={faGoogle} />&nbsp;Se Connecter avec mon Compte UPD&nbsp;<FontAwesomeIcon width="16" icon={faPaw} />
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Login