import React, { Component } from 'react';
import Header from './header';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
          openMenu: false
        }
        this.toggleMenu = this.toggleMenu.bind(this);
      }

    toggleMenu() {
        const Menu = this.state.openMenu;
        this.setState({ openMenu: !Menu, openBasket: false });
    }


    render() {
        const { children } = this.props
        return (
            <div>
                <Header openMenu={this.state.openMenu} toggleMenu={this.toggleMenu} />
                {children}
            </div>
        )
    }

}

export default Layout