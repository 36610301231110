
import React, { Component } from 'react';
import Layout from '../components/layout';
import axios from "axios";
import Table from "../components/table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authed: false,
            email: this.props.location.search !== "" ? queryString.parse(this.props.location.search) === {} ? '' : queryString.parse(this.props.location.search).email : "",
            EmailButtonLoading: false,
            disableClear: true,
            disableField: queryString.parse(this.props.location.search) === {} ? true : false,
            active: false,
            show: false,
            emails: [],
            loading: false

        }
        this.toggleEmail = this.toggleEmail.bind(this);
        this.update = this.udpate.bind(this);
        this.clear = this.clear.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    toggleClass() {
        const currentState = this.state.active
        this.setState({ active: !currentState, show: false })
    }

    clear() {
        this.setState({
            email: '',
            disableClear: true,
            disableField: false,
            show: false
        })
    }

    udpate() {
        var currentComponent = this;
        this.setState({
            disableClear: false,
            disableField: true,
            loading: true
        }, function () {

            const data = "email=" + this.state.email
            axios
                .post("https://europe-west1-upd-ecommerce-data.cloudfunctions.net/front/transactional/data", data, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                        'Authorization': 'Bearer ' + this.props.token
                    },
                })
                .then(response => {
                    //console.log(response.data.events)
                    if (response.data.events !== undefined) {
                        currentComponent.setState({ emails: response.data.events, show: true, loading: false,disableField: false })
                    } else {
                        currentComponent.setState({
                            active: true,
                            email: '',
                            disableClear: true,
                            disableField: false,
                            loading: false

                        })
                    }
                })
                .catch(error => {
                    currentComponent.setState({
                        active: true,
                        email: '',
                        disableClear: true,
                        disableField: false,
                        loading: false

                    })
                })
        });
    }

    toggleEmail(event) {
        this.setState({ email: event.target.value });
    }


    changePage() {
        this.props.history.push(this.state.email === "" ? "/marketing" : '/marketing?email=' + this.state.email)
    }

    render() {
        return (
            <Layout>
                <section className="hero is-large">
                    <div className="hero-body">
                        <div className="container is-widescreen">
                            <div className="columns is-vcentered is-centered">
                                <div className="column is-narrow">
                                    <h1 className="title is-3"><FontAwesomeIcon width="16" icon={faEnvelope} />&nbsp;Liste des e-mails transactionnels d'un client (90 derniers jours)</h1>
                                </div>
                            </div>

                            <div className="columns is-vcentered is-centered">
                                <div className="column is-8">
                                    <div className="field is-horizontal">
                                        <div className="field-body">
                                            <div className="field is-fullwidth">
                                                <div className="control is-expanded">
                                                    <input className="input is-fullwidth" name="email" value={this.state.email} type="email" placeholder="E-mail du client" onChange={this.toggleEmail} />
                                                </div>
                                            </div>
                                            <div className="field is-grouped">
                                                <p className="control">
                                                    <button className={this.state.loading ? "button is-success is-loading" : "button is-success"} disabled={this.state.disableField || !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)} onClick={() => { this.update() }}>
                                                        Rechercher
                                                    </button>
                                                </p>
                                                <p className="control">
                                                    <button className="button is-info" disabled={this.state.email === "" || !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)} onClick={() => { this.changePage() }}>
                                                        Voir les emails promotionnels de ce client
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.show ? <Table emails={this.state.emails} token={this.props.token} /> : null}
                        </div>
                    </div>
                </section>
                <div className={this.state.active ? "modal is-active" : "modal"}>
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Erreur</p>
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={this.toggleClass}
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            Une erreur s'est produite ou cette adresse email n'existe pas dans la base de données!
                        </section>
                        <footer className="modal-card-foot">
                            <button className="button is-primary is-uppercase home-button" onClick={this.toggleClass}>
                                Ok
                            </button>
                        </footer>
                    </div>
                </div>
            </Layout>
        )
    }

}

export default withRouter(Home)